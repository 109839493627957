import { isDefined } from "@app/helpers/kindOf";
import { IBase, IPack, slugify } from "@tickeat/common";
import { UseFormReturn } from "react-hook-form";

export interface PackFormValues extends Partial<IPack> {
  image: any;
}

export const transformPackFormValuesToPayload = (values: Omit<Partial<IPack & { image: any }>, keyof IBase>) => {
  const { catalogToPacks, ...data } = values;

  return {
    ...data,
    name: data.name,
    image: data.image || null,
    tags: data.tags || [],
    slug: slugify(data.name!),
    description: data.description,
    accountCode: data.accountCode || null,
    disabled: data.disabled,
    price: data.price,
    tax: data.tax,
    takeAwayPrice: data.takeAwayPrice || null,
    takeAwayTax: data.takeAwayTax || null,
    deliveryPrice: data.deliveryPrice || null,
    deliveryTax: data.deliveryTax || null,
    onSpotPrice: data.onSpotPrice || null,
    onSpotTax: data.onSpotTax || null,
    orderModes: data.orderModes || [],
    packToProductVariations: data.packToProductVariations || [],
    packToSteps: data.packToSteps || []
  }
}

export const usePackFormSubmit = () => {
  const objectValuesKeys = ['accountCode', 'tax', 'takeAwayTax', 'deliveryTax', 'onSpotPrice'];

  return async (formMethods: UseFormReturn<any, any>, submitCb: (payload: FormData) => void) => {
    const values = formMethods.getValues();

    const payload = transformPackFormValuesToPayload(values);

    const formData = new FormData();

    for (const [key, value] of Object.entries(payload)) {
      if (objectValuesKeys.includes(key)) {
        if (!isDefined(value)) {
          continue;
        }

        for (let [nestedKey, nestedValue] of Object.entries(value) as any) {
          if (isDefined(nestedValue)) {
            formData.append(`${key}[${nestedKey}]`, nestedValue as string);
          }
        }
      } else {
        formData.append(key, Array.isArray(value) ? JSON.stringify(value) : value as string);
      }
    }

    const isValid = await formMethods.trigger();

    if (isValid) {
      submitCb(formData);
    }
  }
}