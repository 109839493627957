import React, { Fragment, memo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { catalogApi } from '@app/store';
import { CatalogDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { CatalogForm } from './CatalogForm';
import { CatalogFormValues, useCatalogFormSubmit } from './catalog-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';
import { CatalogCard } from './CatalogCard';
import { InputFileRef } from '@app/components/form/InputFile';

export const CatalogCreate = memo(() => {
  const toast = useToast();

  const triptych = useTriptych();

  const cardImageRef = useRef<InputFileRef>();

  const onSubmit = useCatalogFormSubmit();

  const [create, { isLoading, error: apiErrors }] = catalogApi.useCreateOneCatalogsMutation();

  const formMethods = useForm<CatalogFormValues>({
    resolver: classValidatorResolver(CatalogDTO),
    defaultValues: {
      name: '',
      promotions: [],
      ticketInfos: {
        logo: undefined,
        bottomLines: ''
      },
      image: null,
      catalogToShops: [],
      catalogToTags: [],
      catalogToPacks: [],
      catalogToProducts: []
    },
  });

  const onCreate = async (formData: FormData) => {
    try {
      const catalog = await create(formData as any).unwrap();

      triptych.close({ mode: 'UPDATE', data: catalog });

      toast.success('Votre catalogue a bien été ajouté.')
    } catch {
      toast.error('Une erreur est survenue lors de la création de votre catalogue.')
    }
  }

  return (
    <Fragment>
      <CatalogCard
        formMethods={formMethods}
        toggle={() => triptych.toggle()}
        onOpenImagePicker={() => cardImageRef.current?.open()} />

      <Panel.Content>

        <Form formMethods={formMethods} apiErrors={apiErrors}>

          <Panel.FormContainer>

            <CatalogForm ref={cardImageRef} />

          </Panel.FormContainer>

          <Panel.Actions>
            <Button
              title="Retour"
              grow
              large
              bordered
              onPress={() => triptych.close()} />

            <Button
              loading={isLoading}
              title="Valider"
              onPress={() => onSubmit(formMethods, onCreate)}
              grow
              large />
          </Panel.Actions>

        </Form>

      </Panel.Content>
    </Fragment>
  );
});
