import React, { useCallback, createElement, useRef, useState } from 'react';
import { Pressable, TextInputProps as RNTextInputProps, View } from 'react-native';
import { isDefined } from '@app/helpers/kindOf';
import { StyledInputs, StyledTextInputProps } from '../styled/form/Inputs';
import { StyledForms } from '../styled/form/Form';
import { useInput } from '@app/hooks/useInput';

export interface DateTimePickerProps extends RNTextInputProps, StyledTextInputProps {
  name: string;
  label: string;
}

export const DateTimePicker = ({ label, name, ...props }: DateTimePickerProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const [blur, setBlur] = useState(true);

  const { field, error, formState } = useInput(name);

  const onChange = (event) => {
    field.onChange(event.target.value);
  }

  const InputDate = useCallback(() => {
    return createElement('input', {
      type: 'date',
      ref,
      style: { display: 'none' },
      onChange
    });
  }, [onChange]);

  const onBlur = () => {
    setBlur(true);
  }

  const onFocus = () => {
    setBlur(false);
  }

  const isFilled = isDefined(field.value);

  const value = field.value ? field.value.toString() : null;

  const open = () => {
    if (ref.current) {
      try {
        ref.current.showPicker();
      } catch (error) {
        window.alert(error);
      }
    }
  }

  return (
    <StyledInputs.Holder>
      <StyledInputs.Label isFilled={isFilled} isDirty={formState.isDirty} isBlurred={blur}>
        {label}
      </StyledInputs.Label>

      {!!error && (
        <StyledForms.Error>
          <StyledForms.ErrorLabel>
            {error}
          </StyledForms.ErrorLabel>
        </StyledForms.Error>
      )}

      <InputDate />

      <Pressable onPress={open}>
        <View pointerEvents="none">
          <StyledInputs.Input
            onChangeText={field.onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            value={value}
            ref={field.ref}
            isBlurred={blur}
            {...props}
          />
        </View>
      </Pressable>

    </StyledInputs.Holder>
  );
};
