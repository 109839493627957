import { TextInput } from "@app/components";
import { PriceForm } from "@app/components/form/PriceForm";
import { Toggle } from "@app/components/form/Toggle";
import { AccountCodeSelect } from "@app/components/form/selects/features/AccountCodeSelect";
import { OrderModeSelect } from "@app/components/form/selects/features/OrderModeSelect";
import React, { forwardRef } from "react";
import { TagSelect } from "@app/components/form/selects/features/TagSelect";
import { StepSelect } from "@app/components/form/selects/features/StepSelect";
import { ProductVariationSelect } from "@app/components/form/selects/features/ProductVariationSelect";
import { StyledForms } from "@app/components/styled/form/Form";
import { InputFile, InputFileRef } from "@app/components/form/InputFile";

export const PackForm = forwardRef<any>((_, ref: React.ForwardedRef<InputFileRef>) => {
  return (
    <StyledForms.Form>

      <InputFile name="image" ref={ref} />

      <TextInput
        name="name"
        label="Nom"
        placeholderTextColor="white"
        autoCapitalize="none" />

      <TextInput
        name="description"
        label="Description"
        placeholderTextColor="white"
        autoCapitalize="none" />

      <Toggle
        name="disabled"
        options={[
          { title: "Actif", value: false },
          { title: "Inactif", value: true },
        ]} />

      <OrderModeSelect
        name="orderModes"
        isMultiple />

      <AccountCodeSelect name="accountCode" />

      <TagSelect name='tags' isMultiple />

      <StepSelect
        name="packToSteps"
        transform={{
          value: (item) => ({ step: item }),
          accessor: (item) => (item.step?._id),
          display: (item) => (item?.step?.name)
        }}
        isMultiple />

      <ProductVariationSelect
        name="packToProductVariations"
        label="Produits offerts"
        transform={{
          value: (item) => ({ productVariation: item }),
          accessor: (item) => (item.productVariation?._id),
          display: (item) => (item?.productVariation?.name)
        }}
        isMultiple />

      <PriceForm
        label="Tarifs"
        names={{
          price: "price",
          tax: "tax",
          takeAwayPrice: "takeAwayPrice",
          takeAwayTax: "takeAwayTax",
          deliveryPrice: "deliveryPrice",
          deliveryTax: "deliveryTax",
          onSpotPrice: "onSpotPrice",
          onSpotTax: "onSpotTax",
        }} />

    </StyledForms.Form>
  );
});
