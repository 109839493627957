import React, { Fragment, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { packApi } from '@app/store';
import { IPack, PackDTO } from '@tickeat/common';
import { Button, CardButtonActionMode, Form, Panel } from '@app/components';
import { PackForm } from './PackForm';
import { PackFormValues, usePackFormSubmit } from './pack-form.helpers';
import { useToast, useTriptych } from '@app/hooks';
import { PackCard } from './PackCard';
import { InputFileRef } from '@app/components/form/InputFile';

const transformToUpdatableFormPack = (pack?: Partial<IPack>): PackFormValues => {
  return {
    name: pack?.name || '',
    image: null,
    description: pack?.description || '',
    accountCode: pack?.accountCode,
    disabled: pack?.disabled,
    price: pack?.price,
    tax: pack?.tax,
    tags: pack?.tags,
    catalogToPacks: pack?.catalogToPacks ?? [],
    takeAwayPrice: pack?.takeAwayPrice,
    takeAwayTax: pack?.takeAwayTax,
    deliveryPrice: pack?.deliveryPrice,
    deliveryTax: pack?.deliveryTax,
    onSpotPrice: pack?.onSpotPrice,
    onSpotTax: pack?.onSpotTax,
    orderModes: pack?.orderModes,
    packToProductVariations: pack?.packToProductVariations,
    packToSteps: pack?.packToSteps
  }
}

interface PackUpdateProps {
  isFetching?: boolean;
  pack?: Partial<IPack>;
  toggle: (mode: CardButtonActionMode) => void;
  onDelete: (product: Partial<IPack>) => void;
}

export const PackUpdate = ({ pack, isFetching, toggle, onDelete }: PackUpdateProps) => {
  const toast = useToast();

  const triptych = useTriptych();

  const cardImageRef = useRef<InputFileRef>();

  const onSubmit = usePackFormSubmit();

  const [update, { isLoading, error: apiErrors }] = packApi.useUpdateOnePacksMutation();

  const formMethods = useForm<PackFormValues>({
    resolver: classValidatorResolver(PackDTO),
    defaultValues: transformToUpdatableFormPack(pack),
  });

  useEffect(() => {
    formMethods.reset(transformToUpdatableFormPack(pack));
  }, [pack, formMethods.reset]);

  const onUpdate = async (formData: FormData) => {
    try {
      const updatedPack = await update({ _id: pack?._id!, formData }).unwrap();

      triptych.close({ mode: 'UPDATE', data: updatedPack });

      toast.success('Votre menu a bien été modifié.')
    } catch {
      toast.error('Une erreur est survenue lors de la modification de votre menu.')
    }
  }

  return (
    <Fragment>

      <PackCard
        pack={pack}
        toggle={toggle}
        formMethods={formMethods}
        onOpenImagePicker={() => cardImageRef.current?.open()} />

      <Panel.Content>
        <Form isLoading={isFetching} formMethods={formMethods} apiErrors={apiErrors}>

          <Panel.FormContainer>

            <Panel.Header>
              <Button
                title="Supprimer"
                bordered
                important
                onPress={() => onDelete(pack!)} />
            </Panel.Header>

            <PackForm ref={cardImageRef} />

          </Panel.FormContainer>

          <Panel.Actions>
            <Button
              title="Retour"
              grow
              large
              bordered
              onPress={() => triptych.close()} />
            <Button
              loading={isLoading}
              onPress={() => onSubmit(formMethods, onUpdate)}
              title="Valider"
              grow
              large />
          </Panel.Actions>
        </Form>

      </Panel.Content>

    </Fragment>
  );
};
