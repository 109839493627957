import React from 'react';
import { MainLayoutHeader, MainLayoutHeaderProps } from '@app/components';

export const SettingHeader = ({ onPress, buttonTitle }: Pick<MainLayoutHeaderProps, 'buttonTitle' | 'onPress'>) => {
  return (
    <MainLayoutHeader
      tabs={[
        { label: 'Catégories', screenName: 'TagScreen' },
        { label: 'Restaurants', screenName: 'ShopScreen' },
        { label: 'Moyens de paiement', screenName: 'PaymentMethodScreen' },
        { label: 'Promotions', screenName: 'PromotionScreen' },
      ]}
      onPress={onPress}
      buttonTitle={buttonTitle} />
  );
};
