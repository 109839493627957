import { createListenerMiddleware } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { authSlice } from './services/auth';
import { Mutex } from 'async-mutex';
import { RootState } from './store';
import { Platform } from 'react-native';
import { IRefreshTokenResponse } from '@app/types';
import { getEnv } from '@app/environment';
import qs from 'qs';

const tagTypes = [
  'Catalogs',
  'CatalogToPacks',
  'CatalogToProducts',
  'Products',
  'ProductVariations',
  'Tags',
  'Variations',
  'Taxes',
  'AccountCodes',
  'Options',
  'Ingredients',
  'Packs',
  'Steps',
  'Shops',
  'PaymentMethods',
  'Promotions'
] as const;

export type TagTypes = typeof tagTypes[number];

export const providesList = <R extends { _id: string }[], T extends TagTypes>(
  resultsWithIds: R | undefined,
  tagType: T,
) => {
  return resultsWithIds
    ? [
      { type: tagType, id: 'LIST' },
      ...resultsWithIds.map(({ _id }) => ({ type: tagType, id: _id })),
    ]
    : [{ type: tagType, id: 'LIST' }];
};

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: getEnv('API_URL'),
  credentials: 'include',
  paramsSerializer: (params) => {
    return qs.stringify(params);
  },
  // headers: { 'content-type': 'application/json' },
  prepareHeaders(headers, { getState }) {
    const { accessToken } = (getState() as RootState).auth;

    if (accessToken) {
      headers.set('authorization', accessToken);
    }

    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const routesExcluded = ['login'];
  const meta = {
    platform: Platform.OS,
  };

  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401 && !routesExcluded.includes(api.endpoint)) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const { refreshToken } = (api.getState() as RootState).auth;

        if (!refreshToken) {
          throw null;
        }

        const refreshResult = await baseQuery(
          { url: '/auth/refresh', method: 'POST', body: { refreshToken } },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          api.dispatch(
            authSlice.actions.setAccessToken(refreshResult.data as IRefreshTokenResponse),
          );

          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(authSlice.actions.logout());
        }
      } catch (e) {
        api.dispatch(authSlice.actions.logout());
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return {
    ...result,
    meta: result.meta && { ...result.meta, ...meta },
  };
};

export const rootApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes,
  keepUnusedDataFor: 60,
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: 60 * 3,
  endpoints: () => ({}),
});

export const rootMiddleware = createListenerMiddleware();