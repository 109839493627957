import { IBase, IImageBySizes } from '../base.interface';
import { ICatalogToProduct } from './catalog-to-product.interface';
import { IOption } from './option.interface';
import { IProductVariation } from './product-variation.interface';
import { ITag } from './tag.interface';

export interface IProduct extends IBase {
  name: string;
  description: string;
  slug: string;
  disabled: boolean;
  imageUrl?: string;
  images?: IImageBySizes;
  options: IOption[];
  optionsIds?: string[];
  tags: ITag[];
  tagsIds: string[];
  productVariations: IProductVariation[];
  productVariationsIds?: string[];
  catalogToProducts?: ICatalogToProduct[];
  position?: number;
  isAlcohol: boolean;
  allergens: Allergens[];
}

export enum Allergens {
  CELERY = "allergen_celery", // "Contains this allergen."
  CRUSTACEANS = "allergen_crustaceans", // "Contains this allergen."
  EGGS = "allergen_eggs", // "Contains this allergen."
  FISH = "allergen_fish", // "Contains this allergen."
  GLUTEN = "allergen_gluten", // "Contains this allergen."
  LUPIN = "allergen_lupin", // "Contains this allergen."
  MILK = "allergen_milk", // "Contains this allergen."
  MOLLUSCS = "allergen_molluscs", // "Contains this allergen."
  MUSTARD = "allergen_mustard", // "Contains this allergen."
  NUTS = "allergen_nuts", // "Contains this allergen."
  PEANUTS = "allergen_peanuts", // "Contains this allergen."
  SESAME_SEEDS = "allergen_sesame_seeds", // "Contains this allergen."
  SOYBEANS = "allergen_soybeans", // "Contains this allergen."
  SULPHUR_DIOXIDE_SULPHITES = "allergen_sulphur_dioxide_sulphites", // "Contains this allergen."
}

export function getAllergenFR(allergen: Allergens): string {
  switch (allergen) {
    case Allergens.CELERY:
      return "Contient du céleri";
    case Allergens.CRUSTACEANS:
      return "Contient des crustacés";
    case Allergens.EGGS:
      return "Contient des œufs";
    case Allergens.FISH:
      return "Contient du poisson";
    case Allergens.GLUTEN:
      return "Contient du gluten";
    case Allergens.LUPIN:
      return "Contient du lupin";
    case Allergens.MILK:
      return "Contient du lait";
    case Allergens.MOLLUSCS:
      return "Contient des mollusques";
    case Allergens.MUSTARD:
      return "Contient de la moutarde";
    case Allergens.NUTS:
      return "Contient des fruits à coque";
    case Allergens.PEANUTS:
      return "Contient des arachides";
    case Allergens.SESAME_SEEDS:
      return "Contient des graines de sésame";
    case Allergens.SOYBEANS:
      return "Contient du soja";
    case Allergens.SULPHUR_DIOXIDE_SULPHITES:
      return "Contient du dioxyde de soufre et des sulfites";
  }
}