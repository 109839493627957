import { IBase, IImageBySizes } from "../base.interface";
import { IPackToStep } from "./pack-to-step.interface";
import { IPackToProductVariation } from "./pack-to-product-variation.interface";
import { ITag } from "./tag.interface";
import { ICatalogToPack } from "./catalog-to-pack.interface";
import { IAccountCode } from "./account-code.interface";
import { ITax } from "./tax.interface";
import { OrderMode } from "./order.interface";

export interface IPack extends IBase {
  name: string;

  description: string;

  slug: string;

  disabled: boolean;

  imageUrl: string;

  price: number;

  deliveryPrice: number;

  takeAwayPrice: number;

  onSpotPrice: number;

  loyaltyPointOffered: number;

  images?: IImageBySizes;

  tags: ITag[];

  tagsIds: string[];

  packToProductVariations: IPackToProductVariation[];

  packToSteps: IPackToStep[];

  catalogToPacks?: ICatalogToPack[];

  accountCode: IAccountCode;

  orderModes: OrderMode[];

  tax: ITax;

  deliveryTax: ITax;

  takeAwayTax: ITax;

  onSpotTax: ITax;
}
