import { IBase } from '../base.interface';
import { ICatalog } from './catalog.interface';
import { OrderMode } from './order.interface';
import { IPack } from './pack.interface';
import { IProductVariation } from './product-variation.interface';
import { ITag } from './tag.interface';

export interface IPromotion extends IBase {
  name: string;

  tags?: ITag[];

  type: PromotionTypes;

  code?: string;

  discountMode?: PromotionDiscountModes;

  discountValue?: number;

  imageUrl?: string;

  description: string;

  disabled: boolean;

  catalogs?: ICatalog[];

  minPrice?: number;

  uniqueByCustomer?: boolean;

  firstOrderOnly?: boolean;

  maxUses?: number;

  orderModes: OrderMode[];

  requiredPacks?: IPack[];

  requiredProductVariations?: IProductVariation[];

  discountablePacks?: IPack[];

  discountableProductVariations?: IProductVariation[];

  discountableProductVariationsIds?: string[];

  catalogShopToPromotions?: any[];

  neededCount?: number;

  offeredCount?: number;

  cumulative?: boolean;

  startDate?: any;

  endDate?: any;

  customersIds?: string[];

  shopsIds?: string[];

  usedCount?: number;

  usedCustomersIds?: string[];

  customerOnly: boolean;

  excludedTagsIds?: string[];

  isPackExcluded?: boolean;
}

export const isPromotionCode = <T extends Partial<IPromotion>>(promotion: T): boolean => {
  return typeof promotion.code !== 'undefined' && promotion.code !== null;
}

export enum PromotionTypes {
  LEAST_EXPENSIVE = 'LEAST_EXPENSIVE',
  DISCOUNT = 'DISCOUNT',
  GIVEAWAY = 'GIVEAWAY'
}

export enum PromotionDiscountModes {
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED'
}
