import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import React, { useEffect, useRef } from 'react';
import { currentRoute, navigationRef } from './RootNavigation';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { ACCESS_TOKEN, authSlice, REFRESH_TOKEN, selectIsAuthenticated } from './store/services/auth';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSidebarNavigator } from './SidebarNavigator';
import { RootStackParamList } from './types';
import { AccountCodeScreen, CatalogScreen, IngredientScreen, Login, NotFound, OptionScreen, PackScreen, PaymentMethodScreen, ProductScreen, PromotionScreen, ShopScreen, StepScreen, TagScreen, VariationScreen } from './screens';
import { ToasterContainer } from './components/toasters/ToasterContainer';
import { useTriptych } from './hooks';

// https://reactnavigation.org/docs/configuring-links
const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  prefixes: ['app://'],
  config: {
    screens: {
      Login: {
        path: 'login',
      },
      AccountCodeScreen: {
        path: 'codes-comptables',
      },
      VariationScreen: {
        path: 'variations',
      },
      ProductScreen: {
        path: 'produits',
      },
      TagScreen: {
        path: 'categories',
      },
      TaxScreen: {
        path: 'taxes',
      },
      PackScreen: {
        path: 'menus'
      },
      StepScreen: {
        path: 'etapes'
      },
      IngredientScreen: {
        path: 'ingredients',
      },
      OptionScreen: {
        path: 'options',
      },
      CatalogScreen: {
        path: 'catalogs'
      },
      PromotionScreen: {
        path: 'promotions'
      },
      PaymentMethodScreen: {
        path: 'moyens-de-paiement'
      },
      ShopScreen: {
        path: 'restaurants'
      },
      NotFound: '*',
    },
  },
};

const Stack = createSidebarNavigator();

const screensWithoutSidebar: (keyof RootStackParamList)[] = [
  'Login'
];

export const Router = () => {
  const dispatch = useAppDispatch();

  const routeNameRef = useRef<string | undefined>();

  const triptych = useTriptych();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const [isAppReady, setAppReady] = React.useState(false);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await AsyncStorage.getItem(ACCESS_TOKEN);
        const refreshToken = await AsyncStorage.getItem(REFRESH_TOKEN);

        dispatch(authSlice.actions.setInitialState({ accessToken, refreshToken }));
      } finally {
        setAppReady(true);
      }
    })();
  }, [dispatch]);

  if (!isAppReady) {
    return null;
  }

  return (
    <NavigationContainer
      linking={linking}
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = currentRoute();
      }}
      onStateChange={() => {
        const currentRouteName = currentRoute();
        const previousRouteName = routeNameRef.current;

        const trackScreenView = () => {
          triptych.reset();
        };

        if (previousRouteName !== currentRouteName) {
          routeNameRef.current = currentRouteName;

          trackScreenView();
        }
      }}
      documentTitle={{
        formatter: (options, route) => `Tick'eat Dashboard - ${options?.title ?? route?.name}`,
      }}>
      <ToasterContainer />

      <Stack.Navigator screensWithoutSidebar={screensWithoutSidebar} screenOptions={{ headerShown: false, animation: 'slide_from_left' }} initialRouteName="Login">
        {isAuthenticated ? (
          <Stack.Group navigationKey={isAuthenticated ? 'user' : 'guest'}>
            <Stack.Screen name="ProductScreen" component={ProductScreen} options={{ title: 'Produits' }} />
            <Stack.Screen name="AccountCodeScreen" component={AccountCodeScreen} options={{ title: 'Codes comptables' }} />
            <Stack.Screen name="VariationScreen" component={VariationScreen} options={{ title: 'Variations' }} />
            <Stack.Screen name="PackScreen" component={PackScreen} options={{ title: 'Menus' }} />
            <Stack.Screen name="StepScreen" component={StepScreen} options={{ title: 'Etapes' }} />
            <Stack.Screen name="IngredientScreen" component={IngredientScreen} options={{ title: 'Ingredients' }} />
            <Stack.Screen name="OptionScreen" component={OptionScreen} options={{ title: 'Options' }} />
            <Stack.Screen name="CatalogScreen" component={CatalogScreen} options={{ title: 'Liste des catalogues' }} />
            <Stack.Screen name="TagScreen" component={TagScreen} options={{ title: 'Catégories' }} />
            <Stack.Screen name="ShopScreen" component={ShopScreen} options={{ title: 'Restaurants' }} />
            <Stack.Screen name="PromotionScreen" component={PromotionScreen} options={{ title: 'Promotions' }} />
            <Stack.Screen name="PaymentMethodScreen" component={PaymentMethodScreen} options={{ title: 'Moyens de paiement' }} />
            <Stack.Screen name="NotFound" component={NotFound} options={{ title: '404' }} />
          </Stack.Group>
        ) : (
          <Stack.Group>
            <Stack.Screen name="Login" component={Login} options={{ title: 'Connexion' }} />
          </Stack.Group>
        )}

      </Stack.Navigator>
    </NavigationContainer>
  );
};

