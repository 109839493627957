import { Button, TextArea, TextInput } from '@app/components';
import { StyledForms } from '@app/components/styled/form/Form';
import React, { forwardRef } from 'react';
import { CatalogOverrides } from './CatalogOverrides';
import { useModal } from '@app/hooks/useModal';
import { TagSelect } from '@app/components/form/selects/features/TagSelect';
import { PromotionSelect } from '@app/components/form/selects/features/PromotionSelect';
import { InputFile, InputFileRef } from '@app/components/form/InputFile';
import { ShopSelect } from '@app/components/form/selects/features/ShopSelect';

const modalId = 'catalog';

export const CatalogForm = forwardRef<any>((_, ref: React.ForwardedRef<InputFileRef>) => {
  const modal = useModal(modalId);

  return (
    <StyledForms.Form>

      <InputFile name="image" ref={ref} />

      <TextInput
        name="name"
        label="Nom"
        autoCapitalize="none" />

      <TextArea
        name="ticketInfos.bottomLines"
        label="Informations bas de ticket" />

      <TagSelect
        name='catalogToTags'
        transform={{
          value: (item) => ({ tag: item }),
          accessor: (item) => (item.tag?._id),
          display: (item) => (item?.tag?.name)
        }}
        isMultiple />

      <ShopSelect name='catalogToShops' isMultiple />

      <PromotionSelect name="promotions" isMultiple />

      <Button title='Composition de mon catalogue' onPress={() => modal.open()} />

      <CatalogOverrides modalId={modalId} />
    </StyledForms.Form>
  );
});
